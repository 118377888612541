import styled from 'styled-components';

const Section = styled.div`
  padding-top: 30px;
  padding-bottom: 150px;
  background-color: #2D3033;
  @media (min-width: 1221px) and (max-width: 8000px) {
    position: relative;
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='1920' height='122' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1918.23 32.511S522.677-1.254 0 122V0h1920' fill='%23393d41'/%3E%3C/svg%3E");
      content: "";
      position: absolute;
      left: 0;
      top: -1px;
      width: 100%;
      height: 122px;
      z-index: 1;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;
    }
  }
  @media (min-width: 1025px) and (max-width: 1220px) {
    position: relative;
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='1920' height='166' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1918.23 32.511S522.677-1.254 0 122V0h1920' fill='%23393d41'/%3E%3C/svg%3E");
      content: "";
      position: absolute;
      left: 0;
      top: -1px;
      width: 100%;
      height: 122px;
      z-index: 1;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    position: relative;
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='1920' height='188' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1918.23 32.511S522.677-1.254 0 122V0h1920' fill='%23393d41'/%3E%3C/svg%3E");
      content: "";
      position: absolute;
      left: 0;
      top: -1px;
      width: 100%;
      height: 122px;
      z-index: 1;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;
    }
  }
  @media (min-width: 480px) and (max-width: 767px) {
    position: relative;
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='1920' height='222' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1918.23 32.511S522.677-1.254 0 122V0h1920' fill='%23393d41'/%3E%3C/svg%3E");
      content: "";
      position: absolute;
      left: 0;
      top: -1px;
      width: 100%;
      height: 122px;
      z-index: 1;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;
    }
  }
  @media (min-width: 1px) and (max-width: 479px) {
    position: relative;
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='1920' height='288' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1918.23 32.511S522.677-1.254 0 122V0h1920' fill='%23393d41'/%3E%3C/svg%3E");
      content: "";
      position: absolute;
      left: 0;
      top: -1px;
      width: 100%;
      height: 122px;
      z-index: 1;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;
    }
  }
  @media (max-width: 1600px) {
    padding-top: 30px;
    padding-bottom: 140px;
  }
  @media (max-width: 1200px) {
    padding-top: 30px;
    padding-bottom: 120px;
  }
  @media (max-width: 1024px) {
    padding-top: 30px;
    padding-bottom: 110px;
  }
  @media (max-width: 768px) {
    padding-top: 20px;
    padding-bottom: 100px;
  }
  .testimonial_area {
    padding-top: 60px;
    @media (max-width: 1600px) {
      padding-top: 55px;
    }
    @media (max-width: 1200px) {
      padding-top: 50px;
    }
    @media (max-width: 1024px) {
      padding-top: 50px;
    }
    @media (max-width: 768px) {
      padding-top: 45px;
    }
    @media (max-width: 480px) {
      padding-top: 40px;
    }
  }
`;


export default Section;
