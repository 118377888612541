import React from 'react';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import Text from 'common/components/Text';

import { ServiceWrapper } from './difference2.style';

const Difference2 = () => {
  return (
    <ServiceWrapper>
      <Container>
        <Box className="blockTitle">
          <Heading as="h2" content="How we can help" />
          <Text as="p" content="We understand that finding and keeping experienced cyber security professionals is exceptionally challenging. We also know that most security teams only have enough resources to focus on reactive tasks, leaving very little time to spend on anything proactive such as: capability uplift, detection rule tuning, implementing new tooling and continuous improvement." />
        </Box>
        <Box className="blockTitle2">
          <Text as="p" content="We can alleviate your concerns by filling the skills gap and providing you with highly experienced technical expertise when you need it most. You can rest assured that when the adversary breaches your first lines of defence you will be best placed to detect and respond with Simply SecOps by your side." />
        </Box>
      </Container>
    </ServiceWrapper>
  );
};

export default Difference2;
