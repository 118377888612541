import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Link from 'common/components/Link';
import Rating from 'common/components/Rating';
import Text from 'common/components/Text';
import React, { useEffect } from 'react';

const Carousel = ({ data, quoteIcon }) => {
  useEffect(() => {
    const glide = new Glide('#glide_carousel', {
      type: 'carousel',
      perView: 1,
      gap: 0,
    });
    glide.mount();
  });

  return (
    <div className="glide" id="glide_carousel">
      <div className="slide__wrapper">
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {data?.map(({ image, logo, client, name, industry, size, threat, risk, risk_text, button, capability, cap, outcome, out1, out2, out3, out4 }, index) => (
              <li className="glide__slide" key={`glide__slide--key${index}`}>
                <div className="testimonial_card">
                  <div className='testimonial_card_body'>
                    <div className="testimonials_left_content">
                      <Heading as="h2" className="testimonial_name" content={client} />
                      <Text as="p" content={industry} />
                      <Text as="p" content={name} />
                      <Text as="p" content={size} />
                      <Text as="p" content={threat} />
                      <br />
                      <div className='reviewer_content_wrapper'>
                        <div className='testimonial_reviewer_image'>
                          <Image src={image?.publicURL} alt={name} />
                        </div>
                      </div>
                    </div>
                    <div className="testimonials_right_content">
                      <div className='testimonial_reviewer_image'>
                        <Image src={image?.publicURL} alt={name} />
                      </div>
                    </div>
                  </div>
                  <div className="testimonial_card_bottom">
                    <div className="testimonials_bottom_content">
                      <Heading as="h2" className="testimonial_name" content={risk} />
                      <Text as="p" content={risk_text} />
                      <Heading as="h2" className="testimonial_name" content={capability} />
                      <Text as="p" content={cap} />
                      <Heading as="h2" className="testimonial_name" content={outcome} />
                      <Text as="p" content={out1} />
                      <Text as="p" content={out2} />
                      <Text as="p" content={out3} />
                      <Text as="p" content={out4} />
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className="glide__bullets"
        data-glide-el="controls[nav]"
      >
        {data?.map((button, index) => (
          <span
            className="glide__bullet"
            data-glide-dir={`=${index}`}
            key={`glide_bullet--key${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
