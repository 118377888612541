import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { rgba } from 'polished';

export const ServiceWrapper = styled.section`
  position: relative;
  background-color: ${themeGet('colors.primary')};
  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: 625px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .container {
    .blockTitle {
      text-align: center;

      @media (max-width: 625px) {
        margin-bottom: 45px;
      }

      p {
        color: ${rgba('#fff', 0.8)};
        font-weight: 450;
        font-size: 18px;
        line-height: 1.9;
        letter-spacing: -0.3px;
        margin-bottom: 0px;
        text-align: center;
        @media only screen and (max-width: 1440px) {
          font-size: 17px;
        }
        @media only screen and (max-width: 1200px) {
          font-size: 17px;
        }
        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
        @media only screen and (max-width: 480px) {
          font-size: 15px;
        }
      }
      h2 {
        font-style: normal;
        font-weight: 200;
        font-size: 42px;
        line-height: 1.15;
        text-align: center;
        margin-bottom: 45px;
        margin-top: 45px;
        letter-spacing: -0.03em;
        color: ${themeGet('colors.white')};

        @media only screen and (max-width: 1440px) {
          font-size: 42px;
        }
        @media only screen and (max-width: 1200px) {
          font-size: 40px;
        }
        @media only screen and (max-width: 1024px) {
          font-size: 38px;
        }
        @media (max-width: 768px) {
          font-size: 36px;
        }
        @media (max-width: 375px) {
          font-size: 32px;
        }
      }
    }
    .blockTitle2 {
      text-align: center;

      @media (max-width: 625px) {
        margin-bottom: 45px;
      }

      p {
        color: ${rgba('#fff', 0.8)};
        font-weight: 500;
        font-size: 18px;
        line-height: 1.9;
        letter-spacing: -0.3px;
        margin-bottom: 25px;
        text-align: center;
        @media only screen and (max-width: 1440px) {
          font-size: 17px;
        }
        @media only screen and (max-width: 1200px) {
          font-size: 16px;
        }
        @media only screen and (max-width: 480px) {
          font-size: 15px;
        }
      }
      h2 {
        font-style: normal;
        font-weight: 200;
        font-size: 42px;
        line-height: 1.15;
        text-align: center;
        margin-bottom: 45px;
        letter-spacing: -0.03em;
        color: ${themeGet('colors.white')};

        @media only screen and (max-width: 1440px) {
          font-size: 40px;
        }
        @media only screen and (max-width: 1200px) {
          font-size: 36px;
        }
        @media only screen and (max-width: 1024px) {
          font-size: 32px;
        }
        @media (max-width: 375px) {
          font-size: 22px;
        }
      }
    }
  }
`;
