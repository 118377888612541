import React from 'react';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';

import { ServiceWrapper } from './theTeam.style';

const TheTeam = () => {
  return (
    <ServiceWrapper>
      <Container>
        <Box className="blockTitle">
          <Heading as="h2" content="About Us" />
        </Box>
      </Container>
    </ServiceWrapper>
  );
};

export default TheTeam;
