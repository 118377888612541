import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Text from 'common/components/Text';
import GatsbyImage from 'common/components/GatsbyImage';
import {
  Section,
  ContentWrapper,
  TextContent,
  Illustration,
  ButtonGroup,
} from './banner.style.js';

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "image/saasMinimal2/banner/logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <Section id="home">
      <Container>
        <ContentWrapper>
          <TextContent>
            <Heading content="Need a Cyber Specialist?" />
            <Text content="At Simply SecOps we specialise in cyber threat detection."/>
            <ButtonGroup>
              <a href="mailto:info@simplysecops.com">
                <Button title="Contact us" />
                <Text as="span" content="" />
              </a>
            </ButtonGroup>
          </TextContent>
          <Illustration>
            <GatsbyImage
              src={
                (data.illustration !== null) | undefined
                  ? data.illustration.childImageSharp.gatsbyImageData
                   : {}
              }
              alt="Illustration"
            />
          </Illustration>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default Banner;
