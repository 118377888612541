import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';

import {
  Section,
  ContentWrapper,
  TimelineWrapper,
  TimelineItem,
  TimelineIndex,
  TimelineContent,
  TimelineDot,
  Hidden,
} from './timeline.style';

const TimelineSection = ({
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  row,
  col,
  button,
  textArea,
  imageArea,
  imageTwo,
  buttonWrapper,
  indexStyle,
  timelineTitle,
  timelineDescription,
}) => {
  const data = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "image/saasMinimal2/illustration.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      saasMinimal2Json {
        about {
          title
          description
        }
      }
    }
  `);

  return (
    <Section id="about">
      <Container>
        <ContentWrapper>
          <Box {...sectionHeader}>
            <Text {...sectionSubTitle} content="WHY CHOOSE US?" />
            <Heading
              {...sectionTitle}
              content=""
            />
          </Box>
          <Box {...row}>
            <Box {...col} {...imageArea}>
              <Fade bottom>
                <GatsbyImage
                  src={
                    (data.illustration !== null) | undefined
                      ? data.illustration.childImageSharp.gatsbyImageData
                      : {}
                  }
                  alt="Illustration"
                />
              </Fade>
            </Box>
            <Box {...col} {...textArea}>
              <TimelineWrapper>
                {data.saasMinimal2Json.about.map((item, index) => (
                  <TimelineItem key={`timeline-item-${index}`}>
                    <TimelineIndex>
                      <Hidden>
                        <Slide bottom>
                          <Text
                            as="span"
                            content=""
                            {...indexStyle}
                          />
                        </Slide>
                      </Hidden>
                    </TimelineIndex>
                    <TimelineContent>
                      <Hidden>
                        <Slide bottom delay={100}>
                          <Heading
                            as="h2"
                            content={item.title}
                            {...timelineTitle}
                          />
                        </Slide>
                      </Hidden>
                      <Hidden>
                        <Slide bottom delay={200}>
                          <Text
                            content={item.description}
                            {...timelineDescription}
                          />
                        </Slide>
                      </Hidden>
                    </TimelineContent>
                    <TimelineDot />
                  </TimelineItem>
                ))}
              </TimelineWrapper>
            </Box>
          </Box>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

TimelineSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageTwo: PropTypes.object,
  buttonWrapper: PropTypes.object,
  indexStyle: PropTypes.object,
  timelineTitle: PropTypes.object,
  timelineDescription: PropTypes.object,
};

TimelineSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: '56px',
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: ['28px', '36px'],
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#BF00FF',
    mb: '20px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
    mb: '50px',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '50%', '50%', '55%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  imageArea: {
    width: ['100%', '100%', '50%', '50%', '45%'],
    mb: ['80px', '90px', '110px', '130px', '140px'],
  },
  title: {
    fontSize: ['30px', '38px', '38px', '48px', '48px'],
    fontWeight: '300',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '15px',
  },
  description: {
    fontSize: '16px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: '33px',
  },
  buttonWrapper: {
    mt: ['25px', '50px'],
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: '46px',
  },
  imageOne: {
    mb: '0px',
    ml: 'auto',
    mr: 'auto',
  },
  imageTwo: {
    ml: 'auto',
    mr: 'auto',
  },
  indexStyle: {
    fontSize: ['36px', '42px', '52px', '56px', '72px'],
    fontWeight: '300',
    color: '#eaebec',
    display: 'block',
    lineHeight: '1',
    mb: '0',
  },
  timelineTitle: {
    fontSize: ['19px', '20px', '21px', '21px', '22px'],
    fontWeight: '500',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: '13px',
    ml: '10px',
  },
  timelineDescription: {
    fontSize: ['14px', '15px', '15px', '15px', '16px'],
    lineHeight: '2',
    color: '#d5d7db',
    mb: '0',
    ml: '10px',
  },
};

export default TimelineSection;
