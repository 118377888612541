import { Modal } from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';
import { ResetCSS } from 'common/assets/css/style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { theme } from 'common/theme/saasMinimal2';
import Seo from 'components/seo';
import Banner from 'containers/SaasMinimal2/Banner';
import Difference1 from 'containers/SaasMinimal2/Difference1';
import Difference2 from 'containers/SaasMinimal2/Difference2';
import Footer from 'containers/SaasMinimal2/Footer';
import Navbar from 'containers/SaasMinimal2/Navbar';
import PricingSection from 'containers/SaasMinimal2/PricingSection';
import {
  ContentWrapper, GlobalStyle
} from 'containers/SaasMinimal2/SaasMinimal2.style';
import ServiceSection from 'containers/SaasMinimal2/ServiceSection';
import TimelineSection from 'containers/SaasMinimal2/TimelineSection';
import SeanBio from 'containers/SaasMinimal2/SeanBio';
import TheTeam from 'containers/SaasMinimal2/TheTeam';
import CaseStudies from 'containers/SaasMinimal2/CaseStudies';
import ProcessSection from 'containers/SaasMinimal2/Process';
import Blog from 'containers/SaasMinimal2/Blog';
import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';


const SaasMinimal2 = () => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Seo title="Simply SecOps" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="is-sticky">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner />
          <Difference1 />
          <ServiceSection />
          <Difference2 />
          <TimelineSection />
          <TheTeam />
          <SeanBio />
          <ProcessSection />
          <CaseStudies />
          <Blog />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default SaasMinimal2;
