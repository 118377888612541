import React from 'react';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';

import { ServiceWrapper } from './blog.style';

const Blog = () => {
  return (
    <ServiceWrapper id="blog">
      <Container>
        <Box className="blockTitle">
          <Heading as="h2" content="Blog" />
        </Box>
        <Box className="blockTitle">
          <Heading as="h3" content="COMING SOON!" />
        </Box>
      </Container>
    </ServiceWrapper>
  );
};

export default Blog;
