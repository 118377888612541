import React from 'react';
import { Icon } from 'react-icons-kit';
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Link from 'common/components/Link';
import Text from 'common/components/Text';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'common/components/GatsbyImage';

import {
  Section,
  ContentWrapper,
  Illustration,
  TextContent,
} from './seanBio.style';

const SeanBio = () => {
  const data = useStaticQuery(graphql`
    query {
      audience: file(
        relativePath: { eq: "image/saasMinimal2/sean-photo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 390
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <Section>
      <Container>
        <ContentWrapper>       
          <Illustration>
            <GatsbyImage
              src={
                (data.audience !== null) | undefined
                  ? data.audience.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Illustration"
            />
          </Illustration>
          <TextContent>
            <Heading
              className="title"
              content="Sean Lengyel"
            />
            <Text
              as="span"
              className="slogan"
              content="Founder"
            />
            <Text
              className="desc"
              content="With 15 years of experience in the field of IT and Cyber Security, Sean has built a unique and deep level of understanding of the cyber threat landscape. Over the past five years, Sean has built and optimised several Security Operations Centres for both commercial and government entities. Having served in a variety of offensive and defensive cyber roles throughout his career, Sean provides clients with holistic methods to tackle the ever-evolving and complex web of cyber threats."
            />
            <Link className="link" href="https://au.linkedin.com/in/sean-len-gyel">
              LinkedIn <Icon size={20} icon={ic_chevron_right} />
            </Link>
          </TextContent>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default SeanBio;
