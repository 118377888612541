import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'common/components/UI/Container';
import Image from 'common/components/Image';
import Link from 'common/components/Link';
import Text from 'common/components/Text';

import {
  Section,
  ContentWrapper,
  Copyright,
  SocialProfiles,
  Icons,
} from './footer.style';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      saasMinimal2Json {
        socialLinks {
          id
          link
          label
          icon {
            publicURL
          }
        }
      }
    }
  `);
  return (
    <Section id="footer">
      <Container>
        <ContentWrapper>
          <Copyright>
            <p>
              Simply SecOps Pty Ltd
            </p>
          </Copyright>
          <Copyright>
            <p>
              ABN: 19 662 648 302
            </p>
          </Copyright>
          <Copyright>
            <p>
              Canberra, ACT, Australia
            </p>
          </Copyright>
          <Copyright>
            <p>
              e: info@simplysecops.com
            </p>
          </Copyright>
          <SocialProfiles>
            <span>Social:</span>
            <Icons>
              {data?.saasMinimal2Json?.socialLinks?.map((item) => (
                <Link key={item.id} href={item.link}>
                  <Image src={item.icon.publicURL} alt={item.label} />
                </Link>
              ))}
            </Icons>
          </SocialProfiles>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default Footer;
