import React from 'react';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import Text from 'common/components/Text';

import { ServiceWrapper } from './difference1.style';

const Difference1 = () => {
  return (
    <ServiceWrapper>
      <Container>
        <Box className="blockTitle">
          <Heading as="h2" content="Who we are" />
          <Text as="p" content="We are a small team of passionate technologists that specialise in the enhancement of your Security Operations (SecOps) monitoring capabilities. More than anything we want to see your Security Operations Centre (SOC) succeed. In addition to years of security operations experience, our team also brings a unique background in IT, DevOps and Red Teaming." />
        </Box>
      </Container>
    </ServiceWrapper>
  );
};

export default Difference1;
