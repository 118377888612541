import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Section = styled.section`
  background-color: ${themeGet('colors.primary')};
  position: relative;
`;

export const ProcessItem = styled.div`
  position: relative;
`;
