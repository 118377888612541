import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { rgba } from 'polished';

export const ServiceWrapper = styled.section`
  position: relative;
  background-color: ${themeGet('colors.primary')};
  padding-top: 90px;
  padding-bottom: 10px;

  @media (max-width: 625px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .container {
    .blockTitle {
      text-align: center;
      margin-bottom: 75px;

      @media (max-width: 625px) {
        margin-bottom: 55px;
      }

      h2,
      p {
        margin: 0;
      }
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 46px;
        line-height: 1.15;
        text-align: center;
        letter-spacing: -0.03em;
        color: ${themeGet('colors.white')};

        @media only screen and (max-width: 1440px) {
          font-size: 46px;
        }
        @media only screen and (max-width: 1200px) {
          font-size: 42px;
        }
        @media only screen and (max-width: 1024px) {
          font-size: 40px;
        }
        @media (max-width: 768px) {
          font-size: 38px;
        }
        @media (max-width: 480px) {
          font-size: 32px;
        }
      }
      p {
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.32;
        color: ${rgba('#fff', 0.7)};
        margin-top: 15px;
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -33px;
      margin-right: -33px;

      @media (max-width: 1199px) {
        margin-left: -15px;
        margin-right: -15px;
      }
    }

    .column {
      flex: 1 1 50%;
      padding-left: 33px;
      padding-right: 33px;
      @media (max-width: 1199px) {
        padding-left: 15px;
        padding-right: 15px;
      }

      @media (max-width: 991px) {
        flex: 1 1 100%;
      }

      @media (max-width: 625px) {
        flex: 1 1 100%;
      }
    }
  }
  .serviceBox {
    position: relative;
    padding-left: 150px;
    margin-bottom: 90px;

    @media (max-width: 625px) {
      margin-bottom: 40px;
    }
    @media (max-width: 480px) {
      padding-left: 0;
      text-align: center;
    }
    > img {
      position: absolute;
      top: 0;
      left: 0;
      transform: rotateY(0deg) perspective(100px);
      transition: transform 500ms ease;

      @media (max-width: 480px) {
        position: relative;
        top: auto;
        left: auto;
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &:hover {
      > img {
        transform: rotateY(180deg) perspective(0px);
      }
    }
    h3 {
      margin: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 176%;
      letter-spacing: -0.01em;
      color: ${themeGet('colors.white')};
      margin-bottom: 10px;
    }
    p {
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 1.73;
      color: ${rgba('#fff', 0.7)};
    }
  }
`;
