import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const ServiceWrapper = styled.section`
  position: relative;
  background-color: ${themeGet('colors.primary')};
  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: 625px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .container {
    .blockTitle {
      text-align: center;

      @media (max-width: 625px) {
        margin-bottom: 45px;
      }

      h2,
      p {
        margin: 0;
      }
      h2 {
        font-style: normal;
        font-weight: 200;
        font-size: 46px;
        line-height: 1.15;
        text-align: center;
        letter-spacing: -0.03em;
        padding-bottom: 150px;
        color: ${themeGet('colors.white')};
        
        @media only screen and (max-width: 1440px) {
          font-size: 44px;
        }
        @media only screen and (max-width: 1200px) {
          font-size: 42px;
        }
        @media only screen and (max-width: 1024px) {
          font-size: 40px;
        }
        @media (max-width: 768px) {
          font-size: 36px;
        }
        @media (max-width: 480px) {
          font-size: 32px;
        }
      }
      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 68px;
        line-height: 1.15;
        text-align: center;
        letter-spacing: -0.03em;
        padding-bottom: 150px;
        color: ${themeGet('colors.white')};
        
        @media only screen and (max-width: 1440px) {
          font-size: 66px;
        }
        @media only screen and (max-width: 1200px) {
          font-size: 64px;
        }
        @media only screen and (max-width: 1024px) {
          font-size: 62px;
        }
        @media (max-width: 768px) {
          font-size: 60px;
        }
        @media (max-width: 480px) {
          font-size: 58px;
        }
      }
    }
  }
`;
