import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { rgba } from 'polished';

export const Section = styled.section`
  background-color: ${themeGet('colors.primary')};
  position: relative;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 10vh;
  @media only screen and (max-width: 1440px) {
    min-height: 8vh;
  }
  @media only screen and (max-width: 1200px) {
    min-height: 7vh;
  }
  @media only screen and (max-width: 1024px) {
    min-height: 6vh;
  }
  @media only screen and (max-width: 480px) {
    min-height: 5vh;
  }
  @media only screen and (max-width: 360px) {
    min-height: 4vh;
  }
`;

export const TextContent = styled.div`
  max-width: 500px;
  @media only screen and (max-width: 1440px) {
    max-width: 450px;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 420px;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 380px;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 350px;
  }
  @media only screen and (max-width: 768px) {
    margin: 130px auto 0;
    text-align: center;
  }
  @media only screen and (max-width: 480px) {
    margin: 150px auto 0;
    text-align: center;
  }
  h2 {
    color: ${themeGet('colors.white')};
    font-weight: 700;
    font-size: 68px;
    line-height: 1.13;
    letter-spacing: -2px;
    margin-bottom: 20px;
    @media only screen and (max-width: 1440px) {
      font-size: 60px;
    }
    @media only screen and (max-width: 1200px) {
      font-size: 50px;
    }
    @media only screen and (max-width: 1024px) {
      font-size: 46px;
    }
  }
  p {
    color: ${rgba('#fff', 0.8)};
    font-weight: 420;
    font-size: 24px;
    line-height: 1.9;
    letter-spacing: -0.3px;
    max-width: 514px;
    margin-bottom: 45px;
    @media only screen and (max-width: 1440px) {
      font-size: 17px;
    }
    @media only screen and (max-width: 1200px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 55px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 15px;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
  button {
    background-color: ${themeGet('colors.white')};
    border-radius: 100px;
    min-height: 60px;
    padding: 0 30px;
    margin-right: 25px;
    span {
      color: ${themeGet('colors.primary')};
    }
    @media only screen and (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 20px;
      min-height: 50px;
    }
  }
  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 1.33;
    color: ${rgba('#fff', 0.6)};
    margin: 0;
  }
`;

export const Illustration = styled.figure`
  margin-left: 150px;
  margin-top: 150px;
  margin-bottom: 70px;
  @media only screen and (max-width: 1440px) {
  }
  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 1024px) {
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
  img {
    max-width: auto;
    @media only screen and (max-width: 1440px) {
      margin-left: 0px;
    }
    @media only screen and (max-width: 1200px) {
      margin-left: 0px;
    }
    @media only screen and (max-width: 1024px) {
      margin-left: 0px;
    }
  }
`;
